// ComboBox Ids
export const CustomerEmail: string = "1";
export const CustomerMobilePhone: string = "2";
export const CustomerBusinesPhone: string = "3";
export const PartnerEmail: string = "4";
export const PartnerMobilePhone: string = "5";
export const PartnerBusinessPhone: string = "6";
export const CustomerAddress: string = "7";
export const PartnerAddress: string = "8";
export const CustomerOtherPhone: string = "9";
export const PartnerOtherPhone: string = "10";
export const CustomerSecondaryEmail: string = "11";
export const PartnerSecondaryEmail: string = "12";

//ComboBox Options
export const comboBoxControlledOptions = [
    { key: 0, text: 'OK To Contact' },
    { key: 1, text: 'Do Not Contact' },
    { key: 2, text: 'Unknown', disabled: true},
    { key: 3, text: 'Unavailable', disabled: true}
];

//TelemetryConstants
export const RefURI = "Masked";
export const EnvironmentNameProperty = "EnvironmentName";
export const EnvironmentName = "Development";
export const ServiceOfferingProperty = "ServiceOffering";
export const ServiceLineProperty = "ServiceLine";
export const ServiceProperty = "Service";
export const ComponentNameProperty = "ComponentName";
export const ComponentIdProperty = "ComponentId";
export const ServiceOffering = "Commercial Sales and Marketing";
export const ServiceLine = "Contact Management";
export const Service = "Contact Master";
export const ComponentName = "Contact Lifecycle";
export const ComponentId = "a71728e4-8b67-43a0-aa45-7a9a4a345ee4";
export const RESTAPICALL = "RESTAPI_CALL";
export const RequestIdAttribute = "RequestId";
export const CorrelationIdAttribute = "CorrelationId";
export const BusinessProcessEventName = "CCM.MsxCpmWidgetUI.API";
export const AppActionAttribute = "AppAction";
export const BusinessProcessEventActionName = "CCM.MsxCpmWidgetUI.Event";
export const ResultAttribute = "Result";
export const Capability = "ContactPreferences";
export const SubCapability = "ContactPreferences";
export const PatchContactInvalidRequestEvent = "PatchContact.Execution.InvalidRequest";
export const PatchContactFailureEvent = "PatchContact.Execution.Failure";
export const PatchContactBeginEvent = "PatchContact.Execution.Begin";
export const PatchContactEndEvent = "PatchContact.Execution.End";
export const GetPreferenceContactInvalidRequestEvent = "GetPreferenceContact.Execution.InvalidRequest";
export const GetPreferenceContactFailureEvent = "GetPreferenceContact.Execution.Failure";
export const GetPreferenceContactBeginEvent = "GetPreferenceContact.Execution.Begin";
export const GetPreferenceContactEndEvent = "GetPreferenceContact.Execution.End";
export const RedAlertMessage = "Something went wrong. Red Alert generated.";
export const InternalExceptionMessage = "Some internal error occurs";
export const SomethingWentWrongMessage = "Something went wrong";
export const ResponseNullMessage = "API sent a NULL response";
export const NotApplicable = "N/A";
export const Production = "Production";
export const IKey = "e6b8944a-eabd-419b-a459-d8c92d35a713";
export const tokenForAPIAuthentication = "tokenForAPIAuthentication";
export const tokenForUTPUserContext = "tokenForUTPUserContext";