import queryString from 'query-string';
import Cookies from 'js-cookie';
let queries = queryString.parse(window.location.search ? window.location.search.toString() : Cookies.get('queryString'));

export const Body = {
    "EmailAddress": queries.EmailAddress ?? null,
    "SecondaryEmailAddress": queries.SecondaryEmailAddress ?? null,
    "BusinessPhone": {
        "FirstName": queries.FirstName ?? null,
        "MiddleName": queries.MiddleName ?? null,
        "LastName": queries.LastName ?? null,
        "PhoneNumber": queries.BusinessPhone ?? null,
        "PreferenceState": queries.PreferenceState ?? null,
        "Country": queries.Country ?? null,
        "TopicId": queries.TopicId ?? null,
    },
    "MobilePhone": {
        "FirstName": queries.FirstName ?? null,
        "MiddleName": queries.MiddleName ?? null,
        "LastName": queries.LastName ?? null,
        "PhoneNumber": queries.MobilePhone ?? null,
        "PreferenceState": queries.PreferenceState ?? null,
        "Country": queries.Country ?? null,
        "TopicId": queries.TopicId ?? null,
    },
    "OtherPhone": {
        "FirstName": queries.FirstName ?? null,
        "MiddleName": queries.MiddleName ?? null,
        "LastName": queries.LastName ?? null,
        "PhoneNumber": queries.OtherPhone ?? null,
        "PreferenceState": queries.PreferenceState ?? null,
        "Country": queries.Country ?? null,
        "TopicId": queries.TopicId ?? null,
    },
    "addressGetRequest": {
        "FirstName": queries.FirstName ?? null,
        "MiddleName": queries.MiddleName ?? null,
        "LastName": queries.LastName ?? null,
        "UnitNumber": queries.UnitNumber ?? null,
        "PreferenceState": queries.PreferenceState ?? null,
        "AddressLine1": queries.AddressLine1 ?? null,
        "AddressLine2": queries.AddressLine2 ?? null,
        "AddressLine3": queries.AddressLine3 ?? null,
        "City": queries.City ?? null,
        "State": queries.State ?? null,
        "Country": queries.Country ?? null,
        "PostalCode": queries.PostalCode ?? null,
        "TopicId": queries.TopicId ?? null,
    },
    "selectedPreference": {
        "PreferenceType": queries.preferenceType ?? null,
        "PreferenceField": queries.preferenceField ?? null,
    },
    "LeadId": queries.LeadId ?? null,
    "EntityName": queries.EntityName ?? null,
    "LoggedInUserId": queries.LoggedInUserId ?? null,
};

export const EntityName = queries.EntityName ?? null;
export const UserId = queries.LoggedInUserId ?? null;