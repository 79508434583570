import { IComboBoxStyles } from "@fluentui/react/lib/components/ComboBox/ComboBox.types";
import { IStackTokens } from "@fluentui/react/lib/components/Stack/Stack.types";


export const SpinnerStyle = {
  left: '0px',
  top: '0px',
  width: '100%',
  height: '100%',
  background: 'url("./loading.gif") no-repeat #fff',
  backgroundPosition: 'left 200px top 50px',
  opacity: '0.8',
};
export const HeadingStyle = {
  fontSize: '10px',
  paddingBottom: '10px'
};
export const SectionStyle = {
  borderTop: '1px solid #e7e4e4',
  margin: '5px',
  padding: '15px 0 10px'
};
export const ExceptionMessageStyle = {
  display: 'inline-block',
  paddingTop: '20px',
  fontSize: '13px',
  color: '#b20000',
  paddingLeft: '5px'
};
export const LabelStyle = {
  fontSize: '13px',
}
export const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: { width: 138 },
};
export const horizontalGapStackTokens: IStackTokens = {
  childrenGap: 48,
};
export const verticalGapStackTokens: IStackTokens = {
  childrenGap: 4,
  padding: 5,
};
