import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

let url = window.location.origin;

const config = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    authority: 'https://login.microsoftonline.com/common/',
    navigateToLoginRequestUrl: true,
    redirectUri: url
  },
  cache: {
    cacheLocation: "localStorage"
  }
};

export const publicClientApplication = new PublicClientApplication(config);

ReactDOM.render(
  (
    <React.StrictMode>
      <MsalProvider instance={publicClientApplication}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Popup}>
          <App />
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </React.StrictMode>
  ),
  document.getElementById('root')
);

reportWebVitals();