import queryString from 'query-string';
import Cookies from 'js-cookie';
import * as Constants from './constants/constants';
import { IComboBox, IComboBoxOption } from '@fluentui/react/lib/components/ComboBox/ComboBox.types';
import { accessToken } from './authn';

export function comboBoxHandler(setSelectedItemForPreferenceType: Function, setIsLoading: Function, setExceptionMessage: Function, event: React.FormEvent<IComboBox>, item?: IComboBoxOption, preferencetype?: string, preferencefield?: number) {
    setIsLoading(true);
    let queries = queryString.parse(window.location.search ? window.location.search.toString() : Cookies.get('queryString'));
    accessToken("tokenForAPIAuthentication")
        .then(
            (token: any) => {
                var aADToken = token.accessToken;
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Authorization": 'Bearer ' + aADToken,
                        Accept: '*/*',
                        "Content-Type": 'application/json',
                    },
                    body: JSON.stringify({
                        "EmailAddress": queries.EmailAddress ?? null,
                        "SecondaryEmailAddress": queries.SecondaryEmailAddress ?? null,
                        "BusinessPhone": {
                            "FirstName": queries.FirstName ?? null,
                            "MiddleName": queries.MiddleName ?? null,
                            "LastName": queries.LastName ?? null,
                            "PhoneNumber": queries.BusinessPhone ?? null,
                            "PreferenceState": queries.PreferenceState ?? null,
                            "Country": queries.Country ?? null,
                            "TopicId": queries.TopicId ?? null,
                        },
                        "MobilePhone": {
                            "FirstName": queries.FirstName ?? null,
                            "MiddleName": queries.MiddleName ?? null,
                            "LastName": queries.LastName ?? null,
                            "PhoneNumber": queries.MobilePhone ?? null,
                            "PreferenceState": queries.PreferenceState ?? null,
                            "Country": queries.Country ?? null,
                            "TopicId": queries.TopicId ?? null,
                        },
                        "OtherPhone": {
                            "FirstName": queries.FirstName ?? null,
                            "MiddleName": queries.MiddleName ?? null,
                            "LastName": queries.LastName ?? null,
                            "PhoneNumber": queries.OtherPhone ?? null,
                            "PreferenceState": queries.PreferenceState ?? null,
                            "Country": queries.Country ?? null,
                            "TopicId": queries.TopicId ?? null,
                        },
                        "addressGetRequest": {
                            "FirstName": queries.FirstName ?? null,
                            "MiddleName": queries.MiddleName ?? null,
                            "LastName": queries.LastName ?? null,
                            "UnitNumber": queries.UnitNumber ?? null,
                            "PreferenceState": queries.PreferenceState ?? null,
                            "AddressLine1": queries.AddressLine1 ?? null,
                            "AddressLine2": queries.AddressLine2 ?? null,
                            "AddressLine3": queries.AddressLine3 ?? null,
                            "City": queries.City ?? null,
                            "State": queries.State ?? null,
                            "Country": queries.Country ?? null,
                            "PostalCode": queries.PostalCode ?? null,
                            "TopicId": queries.TopicId ?? null,
                        },
                        "selectedPreference": {
                            "PreferenceType": preferencetype ?? null,
                            "PreferenceField": preferencefield ?? null,
                        },
                        "LeadId": queries.LeadId ?? null,
                        "EntityName": queries.EntityName ?? null,
                        "LoggedInUserId": queries.LoggedInUserId ?? null,
                    }),
                };
               
                fetch(process.env.REACT_APP_BASEURL + '/api/Preference/PatchContactPoint', requestOptions)
                    .then(
                        (response: any) => response.json())
                    .then((response) => {
                        if (response == null) {
                            setExceptionMessage(Constants.SomethingWentWrongMessage);
                            setIsLoading(false);
                        }
                        else {
                            let msg: string = response.exceptionMessage;
                            setExceptionMessage(msg);
                            setSelectedItemForPreferenceType(item);
                            setIsLoading(false);
                        }
                    },
                        (error: any) => {
                            setExceptionMessage(Constants.SomethingWentWrongMessage);
                            setIsLoading(false);
                        }
                    );
            }
        );
}