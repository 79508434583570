import * as React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { SpinnerStyle, HeadingStyle, SectionStyle, ExceptionMessageStyle, comboBoxStyles, verticalGapStackTokens, horizontalGapStackTokens, LabelStyle } from './style';
import { Body, EntityName } from './constants/bodyForPostRequest';
import { comboBoxControlledOptions } from './constants/constants';
import * as Constants from './constants/constants';
import { comboBoxHandler } from './comboBoxHandlers';
import { Label } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import parse from 'html-react-parser';
import { ComboBox } from '@fluentui/react';
import { IComboBox, IComboBoxOption } from '@fluentui/react/lib/components/ComboBox/ComboBox.types';
import { AuthCheck, accessToken } from './authn';
import { MessageBar } from '@fluentui/react';
import ReactDOM from 'react-dom';


initializeIcons();
require('./style.css');

const App: React.FunctionComponent = () => {

  AuthCheck();

  accessToken(Constants.tokenForUTPUserContext)
    .then(
      (token: any) => {
        var aADToken = token.accessToken;
      }
    );

  const [selectedItemForEmailMTC, setSelectedItemForEmailMTC] = React.useState<IComboBoxOption>();

  const onChangeEmailComboBoxMTC = (event: React.FormEvent<IComboBox>, item?: IComboBoxOption): void => {
    comboBoxHandler(setSelectedItemForEmailMTC, setIsLoading, setExceptionMessage, event, item, Constants.CustomerEmail, Number(item?.key));
  };

  const [selectedItemForSecondaryEmailMTC, setSelectedItemForSecondaryEmailMTC] = React.useState<IComboBoxOption>();
  const onChangeSecondaryEmailComboBoxMTC = (event: React.FormEvent<IComboBox>, item?: IComboBoxOption): void => {
    comboBoxHandler(setSelectedItemForSecondaryEmailMTC, setIsLoading, setExceptionMessage, event, item, Constants.CustomerSecondaryEmail, Number(item?.key));
  };

  const [selectedItemForMobilePhoneMTC, setSelectedItemForMobilePhoneMTC] = React.useState<IComboBoxOption>();

  const onChangeMobilePhoneComboBoxMTC = (event: React.FormEvent<IComboBox>, item?: IComboBoxOption): void => {
    comboBoxHandler(setSelectedItemForMobilePhoneMTC, setIsLoading, setExceptionMessage, event, item, Constants.CustomerMobilePhone, Number(item?.key));
  };


  const [selectedItemForBusinessPhoneMTC, setSelectedItemForBusinessPhoneMTC] = React.useState<IComboBoxOption>();

  const onChangeBusinessPhoneComboBoxMTC = (event: React.FormEvent<IComboBox>, item?: IComboBoxOption): void => {
    
    comboBoxHandler(setSelectedItemForBusinessPhoneMTC, setIsLoading, setExceptionMessage, event, item, Constants.CustomerBusinesPhone, Number(item?.key));
  };


  const [selectedItemForMailMTC, setSelectedItemForMailMTC] = React.useState<IComboBoxOption>();

  const onChangeMailComboBoxMTC = (event: React.FormEvent<IComboBox>, item?: IComboBoxOption): void => {
    comboBoxHandler(setSelectedItemForMailMTC, setIsLoading, setExceptionMessage, event, item, Constants.CustomerAddress, Number(item?.key));
  };


  const [selectedItemForOtherPhoneMTC, setSelectedItemForOtherPhoneMTC] = React.useState<IComboBoxOption>();

  const onChangeOtherPhoneComboBoxMTC = (event: React.FormEvent<IComboBox>, item?: IComboBoxOption): void => {
    comboBoxHandler(setSelectedItemForOtherPhoneMTC, setIsLoading, setExceptionMessage, event, item, Constants.CustomerOtherPhone, Number(item?.key));
  };


  const [selectedItemForEmailPTC, setSelectedItemForEmailPTC] = React.useState<IComboBoxOption>();

  const onChangeEmailComboBoxPTC = (event: React.FormEvent<IComboBox>, item?: IComboBoxOption): void => {
    comboBoxHandler(setSelectedItemForEmailPTC, setIsLoading, setExceptionMessage, event, item, Constants.PartnerEmail, Number(item?.key));
  };

  const [selectedItemForSecondaryEmailPTC, setSelectedItemForSecondaryEmailPTC] = React.useState<IComboBoxOption>();

  const onChangeSecondaryEmailComboBoxPTC = (event: React.FormEvent<IComboBox>, item?: IComboBoxOption): void => {
    comboBoxHandler(setSelectedItemForSecondaryEmailPTC, setIsLoading, setExceptionMessage, event, item, Constants.PartnerSecondaryEmail, Number(item?.key));
  };

  const [selectedItemForMobilePhonePTC, setSelectedItemForMobilePhonePTC] = React.useState<IComboBoxOption>();

  const onChangeMobilePhoneComboBoxPTC = (event: React.FormEvent<IComboBox>, item?: IComboBoxOption): void => {
    comboBoxHandler(setSelectedItemForMobilePhonePTC, setIsLoading, setExceptionMessage, event, item, Constants.PartnerMobilePhone, Number(item?.key));
  };


  const [selectedItemForBusinessPhonePTC, setSelectedItemForBusinessPhonePTC] = React.useState<IComboBoxOption>();

  const onChangeBusinessPhoneComboBoxPTC = (event: React.FormEvent<IComboBox>, item?: IComboBoxOption): void => {
    comboBoxHandler(setSelectedItemForBusinessPhonePTC, setIsLoading, setExceptionMessage, event, item, Constants.PartnerBusinessPhone, Number(item?.key));
  };


  const [selectedItemForMailPTC, setSelectedItemForMailPTC] = React.useState<IComboBoxOption>();

  const onChangeMailComboBoxPTC = (event: React.FormEvent<IComboBox>, item?: IComboBoxOption): void => {
    comboBoxHandler(setSelectedItemForMailPTC, setIsLoading, setExceptionMessage, event, item, Constants.PartnerAddress, Number(item?.key));
  };


  const [selectedItemForOtherPhonePTC, setSelectedItemForOtherPhonePTC] = React.useState<IComboBoxOption>();

  const onChangeOtherPhoneComboBoxPTC = (event: React.FormEvent<IComboBox>, item?: IComboBoxOption): void => {
    comboBoxHandler(setSelectedItemForOtherPhonePTC, setIsLoading, setExceptionMessage, event, item, Constants.PartnerOtherPhone, Number(item?.key));
  };


  const [isLoading, setIsLoading] = React.useState(true);

  const [exceptionMessage, setExceptionMessage] = React.useState("");

  React.useEffect(() => {

    async function ApiCall() {

      accessToken(Constants.tokenForAPIAuthentication)
        .then(
          (token: any) => {
            var aADToken = token.accessToken;
            const requestOptions = {
              method: "POST",
              headers: {
                "Authorization": 'Bearer ' + aADToken,
                Accept: '*/*',
                "Content-Type": 'application/json',
              },
              body: JSON.stringify(Body),
            };
            fetch(process.env.REACT_APP_BASEURL + '/api/Preference/GetPreferences', requestOptions)
              .then(
                (response: any) => response.json())
              .then((response) => {
                if (response == null) {
                  setSelectedItemForBusinessPhoneMTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForBusinessPhonePTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForEmailMTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForEmailPTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForSecondaryEmailMTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForSecondaryEmailPTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForMailMTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForMailPTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForMobilePhoneMTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForMobilePhonePTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForOtherPhoneMTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForOtherPhonePTC(({ key: -1, text: "", disabled: false }));
                  setExceptionMessage(Constants.SomethingWentWrongMessage);
                  setIsLoading(false);
                }
                else {
                  if (response.customerEmailPreferenceType.DisplayValue == 'Red Alert' || response.customerMobilePhonePreferenceType.DisplayValue == 'Red Alert' || response.customerBusinessPhonePreferenceType.DisplayValue == 'Red Alert' || response.partnerMobilePhonePreferenceType.DisplayValue == 'Red Alert' ||
                    response.partnerEmailPreferenceType.DisplayValue == 'Red Alert' || response.partnerBusinessPhonePreferenceType.DisplayValue == 'Red Alert' || response.customerAddressPreferenceType.DisplayValue == 'Red Alert' || response.partnerAddressPreferenceType.DisplayValue == 'Red Alert') {
                    setSelectedItemForBusinessPhoneMTC(({ key: -1, text: "Do Not Contact", disabled: (response.customerBusinessPhonePreferenceType.DisplayValue == 'Red Alert' ? true : false) }));
                    setSelectedItemForBusinessPhonePTC(({ key: -1, text: "Do Not Contact", disabled: (response.partnerBusinessPhonePreferenceType.DisplayValue == 'Red Alert' ? true : false) }));
                    setSelectedItemForEmailMTC(({ key: -1, text: "Do Not Contact", disabled: (response.customerEmailPreferenceType.DisplayValue == 'Red Alert' ? true : false) }));
                    setSelectedItemForEmailPTC(({ key: -1, text: "Do Not Contact", disabled: (response.partnerEmailPreferenceType.DisplayValue == 'Red Alert' ? true : false) }));
                    setSelectedItemForSecondaryEmailMTC(({ key: -1, text: "Do Not Contact", disabled: (response.customerSecondaryEmailPreferenceType.DisplayValue == 'Red Alert' ? true : false) }));
                    setSelectedItemForSecondaryEmailPTC(({ key: -1, text: "Do Not Contact", disabled: (response.partnerSecondaryEmailPreferenceType.DisplayValue == 'Red Alert' ? true : false) }));
                    setSelectedItemForMailMTC(({ key: -1, text: "Do Not Contact", disabled: (response.customerAddressPreferenceType.DisplayValue == 'Red Alert' ? true : false) }));
                    setSelectedItemForMailPTC(({ key: -1, text: "Do Not Contact", disabled: (response.partnerAddressPreferenceType.DisplayValue == 'Red Alert' ? true : false) }));
                    setSelectedItemForMobilePhoneMTC(({ key: -1, text: "Do Not Contact", disabled: (response.customerMobilePhonePreferenceType.DisplayValue == 'Red Alert' ? true : false) }));
                    setSelectedItemForMobilePhonePTC(({ key: -1, text: "Do Not Contact", disabled: (response.partnerMobilePhonePreferenceType.DisplayValue == 'Red Alert' ? true : false) }));
                    setSelectedItemForOtherPhoneMTC(({ key: -1, text: "Do Not Contact", disabled: (response.customerOtherPhonePreferenceType.DisplayValue == 'Red Alert' ? true : false) }));
                    setSelectedItemForOtherPhonePTC(({ key: -1, text: "Do Not Contact", disabled: (response.partnerOtherPhonePreferenceType.DisplayValue == 'Red Alert' ? true : false) }));
                    setExceptionMessage(Constants.SomethingWentWrongMessage);
                    setIsLoading(false);
                  }
                  else {
                    setSelectedItemForBusinessPhoneMTC(({ key: response.customerBusinessPhonePreferenceType.OptionSetId, text: response.customerBusinessPhonePreferenceType.DisplayValue, disabled: response.customerBusinessPhonePreferenceType.IsDisabled }));
                    setSelectedItemForBusinessPhonePTC(({ key: response.partnerBusinessPhonePreferenceType.OptionSetId, text: response.partnerBusinessPhonePreferenceType.DisplayValue, disabled: response.partnerBusinessPhonePreferenceType.IsDisabled }));
                    setSelectedItemForEmailMTC(({ key: response.customerEmailPreferenceType.OptionSetId, text: response.customerEmailPreferenceType.DisplayValue, disabled: response.customerEmailPreferenceType.IsDisabled }));
                    setSelectedItemForEmailPTC(({ key: response.partnerEmailPreferenceType.OptionSetId, text: response.partnerEmailPreferenceType.DisplayValue, disabled: response.partnerEmailPreferenceType.IsDisabled }));
                    setSelectedItemForSecondaryEmailMTC(({ key: response.customerSecondaryEmailPreferenceType.OptionSetId, text: response.customerSecondaryEmailPreferenceType.DisplayValue, disabled: response.customerSecondaryEmailPreferenceType.IsDisabled }));
                    setSelectedItemForSecondaryEmailPTC(({ key: response.partnerSecondaryEmailPreferenceType.OptionSetId, text: response.partnerSecondaryEmailPreferenceType.DisplayValue, disabled: response.partnerSecondaryEmailPreferenceType.IsDisabled }));
                    setSelectedItemForMailMTC(({ key: response.customerAddressPreferenceType.OptionSetId, text: response.customerAddressPreferenceType.DisplayValue, disabled: response.customerAddressPreferenceType.IsDisabled }));
                    setSelectedItemForMailPTC(({ key: response.partnerAddressPreferenceType.OptionSetId, text: response.partnerAddressPreferenceType.DisplayValue, disabled: response.partnerAddressPreferenceType.IsDisabled }));
                    setSelectedItemForMobilePhoneMTC(({ key: response.customerMobilePhonePreferenceType.OptionSetId, text: response.customerMobilePhonePreferenceType.DisplayValue, disabled: response.customerMobilePhonePreferenceType.IsDisabled }));
                    setSelectedItemForMobilePhonePTC(({ key: response.partnerMobilePhonePreferenceType.OptionSetId, text: response.partnerMobilePhonePreferenceType.DisplayValue, disabled: response.partnerMobilePhonePreferenceType.IsDisabled }));
                    setSelectedItemForOtherPhoneMTC(({ key: response.customerOtherPhonePreferenceType.OptionSetId, text: response.customerOtherPhonePreferenceType.DisplayValue, disabled: response.customerOtherPhonePreferenceType.IsDisabled }));
                    setSelectedItemForOtherPhonePTC(({ key: response.partnerOtherPhonePreferenceType.OptionSetId, text: response.partnerOtherPhonePreferenceType.DisplayValue, disabled: response.partnerOtherPhonePreferenceType.IsDisabled }));
                    setExceptionMessage(response.exceptionMessage);
                    setIsLoading(false);
                  }
                }
              }
                ,
                (error: any) => {
                  setSelectedItemForBusinessPhoneMTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForBusinessPhonePTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForEmailMTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForEmailPTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForMailMTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForMailPTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForMobilePhoneMTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForMobilePhonePTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForOtherPhoneMTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForOtherPhonePTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForSecondaryEmailMTC(({ key: -1, text: "", disabled: false }));
                  setSelectedItemForSecondaryEmailPTC(({ key: -1, text: "", disabled: false }));
                  setExceptionMessage(Constants.SomethingWentWrongMessage);
                  setIsLoading(false);
                }
              );
          }
        );
    }
    ApiCall();
  }, []);

  const PartnerToCustomerPrefernces = () => {
    const [showPartnerToCustomerPrefernces, setPartnerToCustomerPrefernces] = React.useState(false)
    const onClickTogglePartnerTabVisibility = () => {
      setPartnerToCustomerPrefernces(true)
    }
    return (
      <div>
        <button id="PartnerToCustomerButton" onClick={onClickTogglePartnerTabVisibility} style={{ marginBlockStart:'0px', marginBlockEnd:'0px', outline: 0, border:'none'}}>
          <h4 aria-label='PARTNER TO CUSTOMER'>PARTNER TO CUSTOMER</h4>
        </button> 
        { showPartnerToCustomerPrefernces ? <PartnerToCustomerPrefernceStack/> : null }
      </div>
    )
  }
  
  const PartnerToCustomerPrefernceStack = () => (
    <div id="PartnerToCustomerPrefernceStack" >
      <Stack style={SectionStyle} tokens={verticalGapStackTokens}>
          <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
              <span>
                <Label htmlFor="partnerEmailComboBox" style={{ ...LabelStyle, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Email</Label>
              </span>
              <span>
                <ComboBox
                  className="partnerEmailComboBox"
                  selectedKey={selectedItemForEmailPTC ? selectedItemForEmailPTC.key : undefined}
                  allowFreeform={false}
                  autoComplete="on"
                  onChange={onChangeEmailComboBoxPTC}
                  placeholder=""
                  options={comboBoxControlledOptions}
                  styles={comboBoxStyles}
                  allowFreeForm = { (selectedItemForEmailPTC !== undefined && (selectedItemForEmailPTC.key==4 || selectedItemForEmailPTC.key == 3)) ? false : true }
                  disabled = { (selectedItemForEmailPTC !== undefined && (selectedItemForEmailPTC.key==4 || selectedItemForEmailPTC.key == 3)) ? true : false }
                  title="EmailPreferenceComboBox"
                  ariaLabel="Select the Partner Email Address Preference"
                />
              </span>
            </Stack>
            <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
              <span>
                <Label htmlFor="partnerBusinessPhoneComboBox" style={{ ...LabelStyle, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Business Phone</Label>
              </span>
              <span>
                <ComboBox
                  className="partnerBusinessPhoneComboBox"
                  selectedKey={selectedItemForBusinessPhonePTC ? selectedItemForBusinessPhonePTC.key : undefined}
                  allowFreeform={false}
                  autoComplete="on"
                  onChange={onChangeBusinessPhoneComboBoxPTC}
                  placeholder=""
                  options={comboBoxControlledOptions}
                  styles={comboBoxStyles}
                  allowFreeForm = { (selectedItemForBusinessPhonePTC !== undefined && (selectedItemForBusinessPhonePTC.key==4 || selectedItemForBusinessPhonePTC.key == 3)) ? false : true }
                  disabled = { (selectedItemForBusinessPhonePTC !== undefined && (selectedItemForBusinessPhonePTC.key==4 || selectedItemForBusinessPhonePTC.key == 3)) ? true : false }
                  title="BusinessPhonePreferenceComboBox"
                  ariaLabel="Select the Partner Business Phone Preference"
                />
              </span>
            </Stack>
            <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
              <span>
                <Label htmlFor="partnerMobilePhoneComboBox" style={{ ...LabelStyle, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Mobile Phone</Label>
              </span>
              <span>
                <ComboBox
                  className="partnerMobilePhoneComboBox"
                  selectedKey={selectedItemForMobilePhonePTC ? selectedItemForMobilePhonePTC.key : undefined}
                  allowFreeform={false}
                  autoComplete="on"
                  onChange={onChangeMobilePhoneComboBoxPTC}
                  placeholder=""
                  options={comboBoxControlledOptions}
                  styles={comboBoxStyles}
                  allowFreeForm = { (selectedItemForMobilePhonePTC !== undefined && (selectedItemForMobilePhonePTC.key==4 || selectedItemForMobilePhonePTC.key == 3)) ? false : true }
                  disabled = { (selectedItemForMobilePhonePTC !== undefined && (selectedItemForMobilePhonePTC.key==4 || selectedItemForMobilePhonePTC.key == 3)) ? true : false }
                  title="MobilePhonePreferenceComboBox"
                  ariaLabel="Select the Partner Mobile Phone Preference"
                />
              </span>
            </Stack>
            
            <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
              <span>
                <Label htmlFor="partnerMailComboBox" style={{ ...LabelStyle, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Mail</Label>
              </span>
              <span>
                <ComboBox
                  className="partnerMailComboBox"
                  selectedKey={selectedItemForMailPTC ? selectedItemForMailPTC.key : undefined}
                  allowFreeform={false}
                  autoComplete="on"
                  onChange={onChangeMailComboBoxPTC}
                  placeholder=""
                  options={comboBoxControlledOptions}
                  styles={comboBoxStyles}
                  allowFreeForm = { (selectedItemForMailPTC !== undefined && (selectedItemForMailPTC.key==4 || selectedItemForMailPTC.key == 3)) ? false : true }
                  disabled = { (selectedItemForMailPTC !== undefined && (selectedItemForMailPTC.key==4 || selectedItemForMailPTC.key == 3)) ? true : false }
                  title="MailingPreferenceComboBox"
                  ariaLabel="Select the Partner Mailing Preference"
                />
              </span>
            </Stack>
            { EntityName == "contact" &&
              <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
                <span>
                  <Label htmlFor="partnerSecondaryEmailComboBox" style={{ ...LabelStyle, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Secondary Email</Label>
                </span>
                <span>
                  <ComboBox
                    className="partnerSecondaryEmailComboBox"
                    selectedKey={selectedItemForSecondaryEmailPTC ? selectedItemForSecondaryEmailPTC.key : undefined}
                    allowFreeform={false}
                    autoComplete="on"
                    onChange={onChangeSecondaryEmailComboBoxPTC}
                    placeholder=""
                    options={comboBoxControlledOptions}
                    styles={comboBoxStyles}
                    allowFreeForm = { (selectedItemForSecondaryEmailPTC !== undefined && (selectedItemForSecondaryEmailPTC.key==4 || selectedItemForSecondaryEmailPTC.key == 3)) ? false : true }
                    disabled = { (selectedItemForSecondaryEmailPTC !== undefined && (selectedItemForSecondaryEmailPTC.key==4 || selectedItemForSecondaryEmailPTC.key == 3)) ? true : false }
                    title="SecondaryEmailPreferenceComboBox"
                    ariaLabel="Select the Partner Secondary Email Address Preference"
                  />
                </span>
              </Stack>
            }
            {
              EntityName == 'contact' &&
              <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
                <span>
                  <Label htmlFor="partnerOtherPhoneComboBox" style={{ ...LabelStyle, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Other Phone</Label>
                </span>
                <span>
                  <ComboBox
                    className="partnerOtherPhoneComboBox"
                    selectedKey={selectedItemForOtherPhonePTC ? selectedItemForOtherPhonePTC.key : undefined}
                    allowFreeform={false}
                    autoComplete="on"
                    onChange={onChangeOtherPhoneComboBoxPTC}
                    placeholder=""
                    options={comboBoxControlledOptions}
                    styles={comboBoxStyles}
                    allowFreeForm = { (selectedItemForOtherPhonePTC !== undefined && (selectedItemForOtherPhonePTC.key==4 || selectedItemForOtherPhonePTC.key == 3)) ? false : true }
                    disabled = { (selectedItemForOtherPhonePTC !== undefined && (selectedItemForOtherPhonePTC.key==4 || selectedItemForOtherPhonePTC.key == 3)) ? true : false }
                    title="OtherPhonePreferenceComboBox"
                    ariaLabel="Select the Partner Other Phone Preference"                          
                  />
                </span>
              </Stack>
            }
          </Stack>
    </div>
  )
  if(!isLoading)
    ReactDOM.render(<PartnerToCustomerPrefernces />, document.querySelector("#partnertocustomerStackList"))

  return (
    <div>
      <div>
        <h5><a href='https://review.docs.microsoft.com/en-us/seller/msx/contacts/contact-preferences?branch=main' target="_blank" aria-label='Privacy Alert'>Privacy Alert</a></h5>
        {isLoading && <div style={{ ...SpinnerStyle, position: 'fixed', zIndex: 9999 }}></div>}
        <Stack>
        {/* {!isLoading && <div role="status">
              <div id="announce" aria-live="assertive" aria-label={exceptionMessage != undefined? exceptionMessage:""} style={ExceptionMessageStyle}>{exceptionMessage != undefined && parse(exceptionMessage)}</div>
          </div>}   */}
        <Stack horizontal>
          <div className="outer" role="main">
            <div className="microsofttocustomer">
              <Stack style={SectionStyle} tokens={verticalGapStackTokens}>
                <div>
                    <button style={{ marginBlockStart:'0px', marginBlockEnd:'0px', outline: 0, border:'none'}}>
                      <h4 aria-label='MICROSOFT TO CUSTOMER'>MICROSOFT TO CUSTOMER</h4>
                    </button>
                </div>
                <Stack tokens={verticalGapStackTokens}>
                  <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
                    <span>
                      <Label htmlFor="mtcEmailComboBox" style={{ ...LabelStyle, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Email</Label>
                    </span>
                    <span>
                      <ComboBox
                        className="mtcEmailComboBox"
                        selectedKey={selectedItemForEmailMTC ? selectedItemForEmailMTC.key : undefined}
                        allowFreeform={false}
                        autoComplete="on"
                        onChange={onChangeEmailComboBoxMTC}
                        placeholder=""
                        options={comboBoxControlledOptions}
                        styles={comboBoxStyles}
                        allowFreeForm = { (selectedItemForEmailMTC !== undefined && (selectedItemForEmailMTC.key==4 || selectedItemForEmailMTC.key == 3)) ? false : true }
                        disabled = { (selectedItemForEmailMTC !== undefined && (selectedItemForEmailMTC.key==4 || selectedItemForEmailMTC.key == 3)) ? true : false }    
                        ariaLabel="Select the Customer Preference email"
                        title="EmailPreferenceComboBox"
                      />
                    </span>
                    </Stack>
                    <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
                    <span>
                      <Label htmlFor="mtcBusinessPhoneComboBox" style={{ ...LabelStyle, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Business Phone</Label>
                    </span>
                    <span>
                      <ComboBox
                        className="mtcBusinessPhoneComboBox"
                        selectedKey={selectedItemForBusinessPhoneMTC ? selectedItemForBusinessPhoneMTC.key : undefined}
                        allowFreeform={false}
                        autoComplete="on"
                        onChange={onChangeBusinessPhoneComboBoxMTC}
                        placeholder=""
                        options={comboBoxControlledOptions}
                        styles={comboBoxStyles}
                        allowFreeForm = { (selectedItemForBusinessPhoneMTC !== undefined && (selectedItemForBusinessPhoneMTC.key==4 || selectedItemForBusinessPhoneMTC.key == 3)) ? false : true }
                        disabled = { (selectedItemForBusinessPhoneMTC !== undefined && (selectedItemForBusinessPhoneMTC.key==4 || selectedItemForBusinessPhoneMTC.key == 3)) ? true : false }    
                        ariaLabel="Select the Customer Business Phone Preference"
                        title="BusinessPhonePreferenceComboBox"
                      />
                    </span>
                  </Stack>
                  <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
                    <span>
                      <Label htmlFor="mtcMobilePhoneComboBox" style={{ ...LabelStyle, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Mobile Phone</Label>
                    </span>
                    <span>
                      <ComboBox
                        className="mtcMobilePhoneComboBox"
                        selectedKey={selectedItemForMobilePhoneMTC ? selectedItemForMobilePhoneMTC.key : undefined}
                        allowFreeform={false}
                        autoComplete="on"
                        onChange={onChangeMobilePhoneComboBoxMTC}
                        placeholder=""
                        options={comboBoxControlledOptions}
                        styles={comboBoxStyles}
                        allowFreeForm = { (selectedItemForMobilePhoneMTC !== undefined && (selectedItemForMobilePhoneMTC.key==4 || selectedItemForMobilePhoneMTC.key == 3)) ? false : true }
                        disabled = { (selectedItemForMobilePhoneMTC !== undefined && (selectedItemForMobilePhoneMTC.key==4 || selectedItemForMobilePhoneMTC.key == 3)) ? true : false }    
                        ariaLabel="Select the Customer Mobile Phone Preference"
                        title="MobilePhonePreferenceComboBox"
                      />
                    </span>
                  </Stack>
                  <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
                    <span>
                      <Label htmlFor="mtcMail" style={{ ...LabelStyle, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Mail</Label>
                    </span>
                    <span>
                      <ComboBox
                        className="mtcMail"
                        selectedKey={selectedItemForMailMTC ? selectedItemForMailMTC.key : undefined}
                        allowFreeform={false}
                        autoComplete="on"
                        onChange={onChangeMailComboBoxMTC}
                        placeholder=""
                        options={comboBoxControlledOptions}
                        styles={comboBoxStyles}
                        allowFreeForm = { (selectedItemForMailMTC !== undefined && (selectedItemForMailMTC.key==4 || selectedItemForMailMTC.key == 3)) ? false : true }
                        disabled = { (selectedItemForMailMTC !== undefined && (selectedItemForMailMTC.key==4 || selectedItemForMailMTC.key == 3)) ? true : false }    
                        ariaLabel="Select the Customer Mailing Preference"
                        title="MailingPreferenceComboBox"
                      />
                    </span>
                    </Stack>
                    {
                      EntityName == 'contact' &&
                      <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
                        <span>
                          <Label htmlFor="mtcSecondaryEmailComboBox" style={{ ...LabelStyle, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Secondary Email</Label>
                        </span>
                        <span>
                          <ComboBox
                            className="mtcSecondaryEmailComboBox"
                            selectedKey={selectedItemForSecondaryEmailMTC ? selectedItemForSecondaryEmailMTC.key : undefined}
                            allowFreeform={false}
                            autoComplete="on"
                            onChange={onChangeSecondaryEmailComboBoxMTC}
                            placeholder=""
                            options={comboBoxControlledOptions}
                            styles={comboBoxStyles}
                            allowFreeForm = { (selectedItemForSecondaryEmailMTC !== undefined && (selectedItemForSecondaryEmailMTC.key==4 || selectedItemForSecondaryEmailMTC.key == 3)) ? false : true }
                            disabled = { (selectedItemForSecondaryEmailMTC !== undefined && (selectedItemForSecondaryEmailMTC.key==4 || selectedItemForSecondaryEmailMTC.key == 3)) ? true : false }        
                            ariaLabel="Select the Customer Preference Secondary email"
                            title="SecondaryEmailPreferenceComboBox"
                          />
                        </span>
                      </Stack>
                    }
                  {
                    EntityName == 'contact' &&
                    <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
                      <span>
                        <Label htmlFor="mtcOtherPhoneComboBox" style={{ ...LabelStyle, fontWeight: 'normal', whiteSpace: 'nowrap' }}>Other Phone</Label>
                      </span>
                      <span>
                        <ComboBox
                          className="mtcOtherPhoneComboBox"
                          selectedKey={selectedItemForOtherPhoneMTC ? selectedItemForOtherPhoneMTC.key : undefined}
                          allowFreeform={false}
                          autoComplete="on"
                          onChange={onChangeOtherPhoneComboBoxMTC}
                          placeholder=""
                          options={comboBoxControlledOptions}
                          styles={comboBoxStyles}
                          allowFreeForm = { (selectedItemForOtherPhoneMTC !== undefined && (selectedItemForOtherPhoneMTC.key==4 || selectedItemForOtherPhoneMTC.key == 3)) ? false : true }
                          disabled = { (selectedItemForOtherPhoneMTC !== undefined && (selectedItemForOtherPhoneMTC.key==4 || selectedItemForOtherPhoneMTC.key == 3)) ? true : false }
                          ariaLabel="Select the Customer Other Phone Preference"
                          title="OtherPhonePreferenceComboBox"
                        />
                      </span>
                    </Stack>
                    }
                </Stack>
              </Stack>
            </div>
            
            <div className="partnertocustomer">
              <Stack style={SectionStyle} tokens={verticalGapStackTokens}>
                <div id="partnertocustomerStackList"></div>                
              </Stack>            
            </div>
          </div>
          </Stack>
          </Stack>
      </div>
    </div>
  );
};
export default App;