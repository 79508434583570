import { useMsal, useAccount } from "@azure/msal-react";
import * as Constants from './constants/constants';

let Msalinstance: any;
let account: any;


export const accessToken = (purpose: string): Promise<any> => {
    var requests: any;
    let scope: any;
    if (purpose.includes(Constants.tokenForUTPUserContext)) {
        scope = ['b2406a33-9114-4fa4-b366-ff1935cc625f/user_impersonation']
    }
    else {
        scope = [process.env.REACT_APP_USER_IMPERSONATION_SCOPE]
    }
    requests = {
        scopes: scope,
        account: account
    };

    return Msalinstance.acquireTokenSilent(requests);
}

export const AuthCheck = () => {
    const { accounts, instance } = useMsal();
    Msalinstance = instance;
    let store = useAccount(accounts[0]);
    account = store;
}
